// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfhighriseintegration from "../../blocks/cfhighriseintegration/src/Cfhighriseintegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Automatedemailsending from "../../blocks/automatedemailsending/src/Automatedemailsending";
import Einvoiceintegration from "../../blocks/einvoiceintegration/src/Einvoiceintegration";
import Aiproductsearchandselection from "../../blocks/aiproductsearchandselection/src/Aiproductsearchandselection";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Cachingaisuggestionsforfutureuse from "../../blocks/cachingaisuggestionsforfutureuse/src/Cachingaisuggestionsforfutureuse";
import Whatsappintegration1 from "../../blocks/whatsappintegration1/src/Whatsappintegration1";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Airetrainingdiy from "../../blocks/airetrainingdiy/src/Airetrainingdiy";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Aifinetuning from "../../blocks/aifinetuning/src/Aifinetuning";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import Price from "../../blocks/landingpage/src/Price.web";
import PriceHistory from "../../blocks/landingpage/src/PriceHistory.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Peritempricing from "../../blocks/peritempricing/src/Peritempricing.web";
import Sapintegration1 from "../../blocks/sapintegration1/src/Sapintegration1";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Servicespecificsettingsadmin from "../../blocks/servicespecificsettingsadmin/src/Servicespecificsettingsadmin";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Analytics1 from "../../blocks/analytics1/src/Analytics1";
import Catalogue1 from "../../blocks/catalogue1/src/Catalogue1";
import ApplicationIndustry from "../../blocks/categoriessubcategories/src/ApplicationIndustry.web"
// import ProductVariation from "../../blocks/productdescription/src/ProductVariation.web"
import ProductVariation from "../../blocks/productdescription/src/ProductVariation.web"
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Ordermanagement1 from "../../blocks/ordermanagement1/src/Ordermanagement1";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import Settings from "../../blocks/settings/src/Settings";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Otpverification from "../../blocks/signuplogin/src/Otpverification.web";
import Signup from "../../blocks/signuplogin/src/Signup.web";
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Platform from '../../blocks/landingpage/src/Platform.web';
import UserCategories from "../../blocks/user-profile-basic/src/UserCategories.web"
import MyCart from "../../blocks/shoppingcart/src/MyCart"
import DebitNotes from "../../blocks/invoicebilling/src/DebitCard.web"
import CreditNotes from "../../blocks/invoicebilling/src/CreditCard.web"
import DebitnoteDeatil from "../../blocks/invoicebilling/src/DebitNoteDetail.web"
import CreditnoteDeatil from "../../blocks/invoicebilling/src/CreditNoteDetail.web"
import Quotations from "../../blocks/shoppingcart/src/Quotations"
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling.web";
import TaxInvoice from "../../blocks/invoicebilling/src/TaxInvoice.web";
import QuotationComparision from "../../blocks/shoppingcart/src/QuotationsComparision.web"
import catalouge from "../../blocks/brandpages/src/Brandcatalouge.web";
import BrandCatalouges from "../../blocks/brandpages/src/Catalouges.web";
import CatalougeDetail from "../../blocks/brandpages/src/Catalougedetail.web";
import Catagories from "../../blocks/user-profile-basic/src/Catagories.web";
import ProjectData from "../../blocks/user-profile-basic/src/ProjectData.web";
import OrderDetail from "../../blocks/dashboard/src/OrderDetail.web";
import PaymentSettlements from "../../blocks/peritempricing/src/PaymentSettlement.web";
import PaymentAcknoledgement from "../../blocks/peritempricing/src/PaymentAcknowledgement.web";
import PaymentTimeline from "../../blocks/peritempricing/src/PaymentTimeline.web";
import Ledger from "../../blocks/peritempricing/src/Ledger.web";
import LedgerDetail from "../../blocks/peritempricing/src/LedgerDetail.web";
import BrandPage from "../../blocks/brandpages/src/Brandpages.web";
import Brands from "../../blocks/brandpages/src/Brands.web";
import Addbrands from "../../blocks/brandpages/src/Addbrands.web";
import AddUPID from "../../blocks/user-profile-basic/src/AddUPID.web";
import ProductList from "../../blocks/user-profile-basic/src/ProductList.web";
import AddProduct from "../../blocks/user-profile-basic/src/AddProduct.web";
import LogisticalList from "../../blocks/brandpages/src/LogisticalList.web";
import AddLogistical from "../../blocks/brandpages/src/AddLogistical.web";


const routeMap = {
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},

Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
Servicespecificsettingsadmin:{
 component:Servicespecificsettingsadmin,
path:"/Servicespecificsettingsadmin"},
Signuplogin:{
 component:Signuplogin,
path:"/Signuplogin"},
Analytics1:{
 component:Analytics1,
path:"/Analytics1"},
Catalogue1:{
 component:Catalogue1,
path:"/Catalogue1"},
ApplicationIndustry:{
  component:ApplicationIndustry,
 path:"/ApplicationIndustry"},
 ProductVariation:{
  component:ProductVariation,
 path:"/ProductVariation"},
Termsandconditions:{
 component:Termsandconditions,
path:"/Termsandconditions"},
Ordermanagement1:{
 component:Ordermanagement1,
path:"/Ordermanagement1"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Dashboard:{
  component:Dashboard,
 path:"/Dashboard"},
 OrderDetail:{
  component:OrderDetail,
 path:"/OrderDetail"},
 PaymentSettlements:{
  component:PaymentSettlements,
 path:"/PaymentSettlements"},
 PaymentAcknowledgement:{
  component:PaymentAcknoledgement,
 path:"/PaymentAcknowledgement"},
 PaymentTimeline:{
  component:PaymentTimeline,
 path:"/PaymentTimeline"},
Dashboard4:{
 component:Dashboard4,
path:"/Dashboard4"},
Settings:{
 component:Settings,
path:"/Settings"},

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfhighriseintegration:{
 component:Cfhighriseintegration,
path:"/Cfhighriseintegration"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Categories:{
  component:Catagories,
 path:"/Categories"},
 Ledger:{
  component:Ledger,
 path:"/Ledger"},
 LedgerDetail:{
  component:LedgerDetail,
 path:"/LedgerDetail"},
ProjectData:{
  component:ProjectData,
  path:"/ProjectData"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Automatedemailsending:{
 component:Automatedemailsending,
path:"/Automatedemailsending"},
Einvoiceintegration:{
 component:Einvoiceintegration,
path:"/Einvoiceintegration"},
Aiproductsearchandselection:{
 component:Aiproductsearchandselection,
path:"/Aiproductsearchandselection"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cachingaisuggestionsforfutureuse:{
 component:Cachingaisuggestionsforfutureuse,
path:"/Cachingaisuggestionsforfutureuse"},
Whatsappintegration1:{
 component:Whatsappintegration1,
path:"/Whatsappintegration1"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Airetrainingdiy:{
 component:Airetrainingdiy,
path:"/Airetrainingdiy"},
Price:{
  component:Price,
 path:"/Price"},
 PriceHistory:{
  component:PriceHistory,
 path:"/PriceHistory/:matId/:subMatId/:selMatId"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Aifinetuning:{
 component:Aifinetuning,
path:"/Aifinetuning"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
ProductDescription:{
  component:ProductDescription,
  path:"/ProductDescription/:navigationBarTitleText"},
  LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Peritempricing:{
 component:Peritempricing,
path:"/Peritempricing"},
Sapintegration1:{
 component:Sapintegration1,
path:"/Sapintegration1"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Platform:{
  component:Platform,
 path:"/Platform"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Otpverification:{
  component:Otpverification,
 path:"/Otpverification"},
 Signup:{
  component:Signup,
 path:"/Signup"},
  Signuplogin: {
    component: Signuplogin,
    path: "/Signuplogin"
  },
  UserCategories:{
    component:UserCategories,
   path:"/UserCategories"},
   MyCart:{
     component: MyCart,
    path:"/MyCart"},
    Quotations:{
      component: Quotations,
     path:"/Quotations"},
     DebitNotes:{
      component: DebitNotes,
     path:"/DebitNotes"},
     CreditNotes:{
      component: CreditNotes,
     path:"/CreditNotes"},
     DebitnoteDeatil:{
      component: DebitnoteDeatil,
     path:"/DebitnoteDeatil"},
     CreditnoteDeatil:{
      component: CreditnoteDeatil,
     path:"/CreditnoteDeatil"},
     InvoiceBilling:{
      component:InvoiceBilling,
     path:"/InvoiceBilling"},
     TaxInvoice:{
      component:TaxInvoice,
     path:"/TaxInvoice"},
      QuotationComparision:{
        component: QuotationComparision,
      path:"/QuotationComparision/:navigationBarTitleText"},
      catalouge:{
        component:catalouge,
       path:"/catalouge"},
       BrandCatalouges:{
        component:BrandCatalouges,
       path:"/BrandCatalouges"},
       CatalougeDetail:{
        component:CatalougeDetail,
       path:"/CatalougeDetail"},
      BrandPage:{
        component:BrandPage,
       path:"/BrandPage"},
       Brands:{
        component:Brands,
       path:"/Brands"},
       Addbrands:{
        component:Addbrands,
       path:"/Addbrands"},
       AddUPID:{
        component:AddUPID,
       path:"/AddUPID"},
       ProductList:{
        component:ProductList,
       path:"/ProductList"},
       AddProduct:{
        component:AddProduct,
       path:"/AddProduct"},
       LogisticalList:{
        component:LogisticalList,
       path:"/LogisticalList"},
       AddLogistical:{
        component:AddLogistical,
       path:"/AddLogistical"},
  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;