import React from "react";

import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Menu,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import BrandcatalougeController, {
  Props
} from "./BrandcatalougeController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import { filterIcon, seacrhIcon, sortingIcon } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
// Customizable Area End

export default class Brandcatalouge extends BrandcatalougeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cartTable = () => {
    const tabData = [
      { label: 'All Results', value: 0 },
    ];
    
    return (
      <Box style={{ width: "94%", ...webStyle.mainContainer }}>
        <Box style={webStyle.header}>
          <Typography variant="h6" style={webStyle.heading}>
          Brand Catalogue
          </Typography>
          <Button variant="contained" style={{ ...webStyle.checkoutButton,fontFamily: "Inter, sans-serif", textTransform: "none", boxShadow: "none" }} onClick={()=>this.navigateToBrandCatalogue("/Addbrands")}>
          Add New Brand
          </Button>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
           <Tabs value={0} TabIndicatorProps={{
  style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
}}>
  {tabData.map(({ label, value }) => (
    <Tab
      key={value}
      style={{ textTransform: "none", height: "58px" }}
      label={
        <Typography style={webStyle.activeTab}>
          {label}
        </Typography>
      }
    />
  ))}
</Tabs>
            </Box>
            <Box style={webStyle.searchContainer}>
              <InputBase
                placeholder="Search"
                style={webStyle.searchInput}
                inputProps={{
                  style: {
                    fontFamily: 'inter, sans-serif',
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon} alt="sorting icon" data-testId="filterIcons" style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon} alt="filter icon" data-testId="MoreVertIcon" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
  <Table aria-label="simple table">
    <TableHead style={{ ...webStyle.tableHead }}>
      <TableRow style={{ borderBottom: "none" }}>
        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
        <TableCell align="left" style={{ ...webStyle.TableCell, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
          Brand Name
        </TableCell>
        <TableCell align="left" style={{ ...webStyle.TableCell, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
          Industry / Sector
        </TableCell>
        <TableCell align="left" style={{ ...webStyle.TableCell, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
          No of Catalogue
        </TableCell>
        <TableCell align="left" style={{ ...webStyle.TableCell, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
          No of Products
        </TableCell>
        <TableCell align="left" style={{ ...webStyle.TableCell, borderBottom: "none" }}>
          Actions
        </TableCell>
        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={webStyle.Tbody}>
      <TableRow style={{ height: "30px" }}></TableRow>
      {this.state.brandcatalogueNamesList.map((item, index) => (
        <TableRow key={index} style={{ height: "45px" }}  onClick={()=>this.navigateToBrandCatalogue("/BrandCatalouges")} >
          <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
          <TableCell style={{ ...webStyle.cell1, borderTop: "1px solid #E7E2F0" }}>
            {item.name}
          </TableCell>
          <TableCell style={{ ...webStyle.cell, borderTop: "1px solid #E7E2F0" }}>
            {item.industry}
          </TableCell>
          <TableCell style={{ ...webStyle.cell, borderTop: "1px solid #E7E2F0" }}>
            {item.no_of_catalogues}
          </TableCell>
          <TableCell style={{ ...webStyle.cell, borderTop: "1px solid #E7E2F0" }}>
            {item.no_of_prducts}
          </TableCell>
          <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
            <IconButton
              aria-label="more"
              onClick={this.handleMenuClick}
              data-testid="MoreIcon"


            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClick={this.handleMenuClose}
              PaperProps={{
                style: { boxShadow: 'none', border: "1px solid #E7E2Ef", width: "80px" },
              }}
            >
              <MenuItem data-testId="EditMenuItem" >Edit</MenuItem>
              <MenuItem data-testId="DeleteMenuItem">Delete</MenuItem>
            </Menu>
          </TableCell>
          <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>
              {this.getResultText(this.state.currentPage)}
            </span>
            <Pagination
              count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
              page={this.state.currentPage}
              onChange={this.handlePageChange}
              siblingCount={0}
              boundaryCount={1}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  style={{
                    textDecoration: 'underline',
                    borderRadius: 0,
                    background: "none",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#979797"
                  }}
                />
              )}
            />
          </div>
        </TableCell>
        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
      </TableRow>
      <TableRow style={{ height: "30px" }}></TableRow>
    </TableBody>
  </Table>
</TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() { 
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar  />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.cartTable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number;
  };
} = {
  mainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter, sans-serif",
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
  },
  checkoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  Tbody: {
    padding: "20px",
  },
  cell1: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
  },
  cell: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842",
  },
  tableHead: {
    backgroundColor: "#f3f4f6",
  },
  TableCell: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "265px",
  },
  searchInput: {
    width: "173px",
    height: "35px",
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  activeTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
};
// Customizable Area End
