import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message"
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
export type DropdownOption = {
  id: number;
  name: string;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  value1: number | 0;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  supportContact: string;
  upid: any;
  selectedStandard:any;
  upidName: any;
  versionId: any;
  productVersionName: any;
  alias: string;
  quantity: string;
  productSubcategory: any;
  productCategory: any;
  productDescription: string;
  operators: string;
  quantity1: string;
  selectedBrandId:any;
  hsncode: string;
  heading: string;
  subheading: string;
  productDescription1: string;
  cgst: string;
  sgst: string;
  igst: string;
  materialcomp: string;
  feature: string;
  key: string;
  unit: string;
  productcatag: any;
  productsubcatag: any;
  productType: string;
  physicalForm: string;
  productTypeOptions: any,
  physicalFormOptions: any,
  sampleAvail: string;
  unitOfMeasure: string;
  secondunitOfMeasure: string;
  unitConversion: string;
  pictureFile: File;
  brands: string;
  brandCollectName: string;
  standardPackage: string;
  masterPackage: string;
  colour: string;
  material: string;
  lengthInInch: string;
  widthInInch: string;
  InletSize: string;
  thickness: string;
  brandProduct: string;
  brandSKU: string;
  standardPackage1: string;
  masterPackage1: string;
  IsNumber: string;
  productDescript: string;
  errors:any;
  productId: any;
  documentFile: File;
  qualityFile1: File;
  qualityTest1: File;
  docTitle: string;
  certificateName: string;
  qualityTestName: string;
  brandData:any;
  error: string;
  error1: string;
  pictureFileName: string;
  documentFileName: string;
  qualityFile1Name: string;
  qualityTest1Name: string;
  userId: number;
  keys: string[];
  keys1: string[];
  keys2: string[];
  units: string[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addDataCall: string | Message = ''
  addfeatureCall: string | Message = ''
  addbrandDetailsCall: string | Message = ''
  addstandardsCall: string | Message = ''
  adddescriptionCall: string | Message = ''
  adddetailSpecificationCall: string | Message = ''
  addimagesCall: string | Message = ''
  adddocumentsCall: string | Message = ''
  addcertificationCall: string | Message = ''
  getphysicalformApiCallId: any;
  getproducttypeApiCallId: any;
  getBrandsApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      openDialog: true,
      errors:"",
      selectedStandard:"",
      upidName: "",
      versionId: "",
      activeComponent: "Company",
      selectedBrandId:"",
      anchorEl: null,
      brandData:[],
      value: 0,
      value1: 0,
      totalResults: 140,
      productCategory: "",
      resultperPage: 12,
      currentPage: 1,
      supportContact: "",
      upid: "",
      productId: "",
      productVersionName: "",
      alias: "",
      quantity: "",
      quantity1: "",
      productSubcategory: "",
      productDescription: "",
      productDescription1: "",
      operators: '',
      hsncode: "",
      heading: "",
      subheading: "",
      cgst: "",
      sgst: "",
      igst: "",
      materialcomp: "",
      feature: "",
      key: "",
      unit: "",
      productcatag: "",
      productsubcatag: "",
      productType: "",
      physicalForm: "",
      productTypeOptions: [] as DropdownOption[],
      physicalFormOptions: [] as DropdownOption[],
      sampleAvail: "",
      unitOfMeasure: "",
      secondunitOfMeasure: "",
      unitConversion: "",
      pictureFile: new File([], ""),
      documentFile: new File([], ""),
      qualityFile1: new File([], ""),
      qualityTest1: new File([], ""),
      brands: "",
      brandCollectName: "",
      standardPackage: "",
      masterPackage: "",
      colour: "",
      material: "",
      lengthInInch: "",
      widthInInch: "",
      InletSize: "",
      thickness: "",
      brandProduct: "",
      brandSKU: "",
      standardPackage1: "",
      masterPackage1: "",
      IsNumber: "",
      productDescript: "",
      docTitle: "",
      certificateName: "",
      qualityTestName: "",
      error: "",
      error1: "",
      pictureFileName: "",
      documentFileName: "",
      qualityFile1Name: "",
      qualityTest1Name: "",
      userId: 0,
      keys: Array(8).fill(""),
      keys1: Array(10).fill(""),
      keys2: Array(15).fill(""),
      units: Array(15).fill(""),

      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount();
    this.getPhysicalForm("GET");
    this.getProductType('GET');
    this.getBrandsAPICall();
    const localStorageData = (typeof localStorage !== "undefined" && localStorage.getItem('user-id')) || '';

    const number = parseInt(localStorageData)
    const category = localStorage.getItem('CategoryId');
    const subcategory = localStorage.getItem('SubcategoryId');
    const CategoryName = localStorage.getItem('CategoryName');
    const SubcategoryName = localStorage.getItem('SubcategoryName');
    const selectedVersionName = localStorage.getItem('SelectedVersionName');
    const selectedUpidName = localStorage.getItem('SelectedUpidName');
    const selectedUpidId = localStorage.getItem('SelectedUpidId')
    const selectedVersionId = localStorage.getItem('SelectedVersionId')

    this.setState({ userId: number, productcatag: category, productCategory: CategoryName, productsubcatag: subcategory, productVersionName: selectedVersionName, productSubcategory: SubcategoryName, upidName: selectedUpidName, upid: selectedUpidId, versionId: selectedVersionId })
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (apiRequestCallId === this.getphysicalformApiCallId) {
        this.handleGetPhysicalResponse(responseJson);
      }
      else if (apiRequestCallId === this.getproducttypeApiCallId) {
        this.handleGetProductResponse(responseJson);
      }
      else if (apiRequestCallId === this.addDataCall) {
        this.handleAddProductResponse(responseJson);
      }
      else if (apiRequestCallId === this.getBrandsApiCallId) {
        this.handleGetBrandProductResponse(responseJson);
      }


    }
  }
  handleGetBrandProductResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({brandData:responseJson?.data})
    }
  }
  handleAddProductResponse(responseJson: any) {
    if (responseJson?.data) {
      console.log('shyam data ', responseJson?.data.id);
      this.setState({ productId: responseJson?.data.id })
    }
  }

  handleGetProductResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({
        productTypeOptions: responseJson.data.map((item: any) => ({
          id: item.attributes.id.toString(),
          name: item.attributes.name,
        })),
      });
    }
  }

  handleGetPhysicalResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({
        physicalFormOptions: responseJson.data.map((item: any) => ({
          id: item.attributes.id.toString(),
          name: item.attributes.name,
        })),
      });
    }
  }
  
  validateProductFields = (): boolean => {
    const errors: Record<string, string> = {};
    const {
      productType,
      physicalForm,
      materialcomp,
      productVersionName,
    } = this.state;
  
    if (!productType?.trim()) errors.productType = "Product Type is required.";
    if (!physicalForm?.trim()) errors.physicalForm = "Physical Form is required.";
    if (!materialcomp?.trim()) errors.materialcomp = "Material Composition is required.";
    if (!productVersionName?.trim()) errors.productVersionName = "Product Version Name is required.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0;
  };

  handleSubmit = () => {
    if (this.validateProductFields()) {
      this.setState({value:1})
      this.postAddDataApi();
      console.log("Form submitted successfully!");
    } else {
      console.log("Validation failed.");
    }
  };

  validateBrandDetailsFields = (): boolean => {
    const errors: Record<string, string> = {};
    const {
      brandProduct,
      brandSKU,
      standardPackage1,
      masterPackage1,
    } = this.state;
  
    if (!brandProduct?.trim()) errors.brandProduct = "Brand Product Name is required.";
    if (!brandSKU?.trim()) errors.brandSKU = "Brand SKU Number is required.";
    if (!standardPackage1?.trim()) errors.standardPackage1 = "Standard Packaging is required.";
    if (!masterPackage1?.trim()) errors.masterPackage1 = "Master Packaging is required.";
  
    if (!this.state.selectedBrandId) errors.selectedBrand = "Brand is required.";   
    this.setState({ errors });
  
    return Object.keys(errors).length === 0;
  };

  validateProductDescription = (): boolean => {
    const errors: Record<string, string> = {};
    const { productDescript } = this.state;
  
    if (!productDescript?.trim()) {
      errors.productDescript = "Product Description is required.";
    }
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };
  handleSubmits1 = () => {
   
    if (this.validateProductDescription()) {
      console.log("Form submitted successfully!");
      this.setState({ value: 5 });
      this.productDescriptionApi();
    } else {
      console.log("Validation failed.");
    }
  };
  
  handleSubmits = () => {
   
    if (this.validateBrandDetailsFields()) {
      console.log("Form submitted successfully!");
      this.setState({value:3})
      this.brandDetailsApi();
    } else {
      console.log("Validation failed.");
    }
  };

  
  
  
  
  
  getBrandsAPICall = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getBrandsApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBrandsEndPoints
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
;

  // Handle brand selection
  handleBrandChange = (event:any, value:any) => {
    if (value) {  
      console.log("Selected Brand ID:", value.id); 
      this.setState({selectedBrandId:value.id})
    }
  };

  
  postAddDataApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };

    const formData = new FormData();
    formData.append("product[product_identifier]", this.state.upid)
    formData.append("product[upid_id]", this.state.upid)
    formData.append("product[upid_version_id]", this.state.versionId)
    formData.append("product[product_version_name]", this.state.productVersionName)
    formData.append("product[alias][]", this.state.alias);
    formData.append("product[category_id]", this.state.productcatag)
    formData.append("product[sub_category_id]", this.state.productsubcatag)
    formData.append("product[product_description]", this.state.productDescription)
    formData.append("product[product_type]", this.state.productType)
    formData.append("product[physical_form]", this.state.physicalForm)
    formData.append("product[material_composition][]", this.state.materialcomp)
    formData.append("product[sample_availability]", this.state.sampleAvail)
    formData.append("product[hsn_code_number]", this.state.hsncode)
    formData.append("product[heading]", this.state.heading)
    formData.append("product[sub_heading]", this.state.subheading)
    formData.append("product[description]", this.state.productDescription1)
    formData.append("product[cgst]", this.state.cgst)
    formData.append("product[sgst]", this.state.sgst)
    formData.append("product[igst]", this.state.igst)
    formData.append("product[unit_of_measurement]", this.state.unitOfMeasure)
    formData.append("product[secondary_unit_of_measurement]", this.state.secondunitOfMeasure)
    formData.append("product[unit_conversion]", this.state.unitConversion)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addProductApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  postfeatureApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[brand_name]", this.state.brands);
    formData.append("product[brand_collection_name]", this.state.brandCollectName);
    formData.append("product[standard_packaging]", this.state.standardPackage);
    formData.append("product[master_packaging]", this.state.masterPackage);
    formData.append("product[colour]", this.state.colour);
    formData.append("product[material]", this.state.material);
    formData.append("product[length_in_inch]", this.state.lengthInInch);
    formData.append("product[width_in_inch]", this.state.widthInInch);
    formData.append("product[inlet_size]", this.state.InletSize);
    formData.append("product[thickness]", this.state.thickness);
    formData.append("context", 'features');


    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addfeatureCall = requestMessage1.messageId;
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);

    return true;


  }

  brandDetailsApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[brand_name]", '134');
    formData.append("product[brand_collection_name]", '1');
    formData.append("product[brand_product_name]", this.state.brandProduct);
    formData.append("product[brand_sku]", this.state.brandSKU);
    formData.append("product[standard_packaging]", this.state.standardPackage1);
    formData.append("product[master_packaging]", this.state.masterPackage1);
    formData.append("context", 'brand_details');
    formData.append("product[brand_id]", this.state.selectedBrandId);


    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addbrandDetailsCall = requestMessage2.messageId;
    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);

    return true;


  }

  standardsApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[standards]", 'IS');
    formData.append("product[is_number]", this.state.IsNumber);
    formData.append("context", 'standards');

    const requestMessage3 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addstandardsCall = requestMessage3.messageId;
    requestMessage3.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage3.id, requestMessage3);

    return true;


  }

  productDescriptionApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[product_description_long]", this.state.productDescript);
    formData.append("context", 'product_descriptions');

    const requestMessage4 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adddescriptionCall = requestMessage4.messageId;
    requestMessage4.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage4.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage4.id, requestMessage4);
    return true;
  }

  detailSpecificationApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("context", 'specifications');
    formData.append("product[specification][Brand Details][0][feture]", 'true');
    formData.append("product[specification][Brand Details][0][key]", 'Brand');
    formData.append("product[specification][Brand Details][0][value]", 'select');
    formData.append("product[specification][Brand Details][1][feture]", 'false');
    formData.append("product[specification][Brand Details][1][key]", 'ISIN');
    formData.append("product[specification][Brand Details][1][value]", 'Select');
    formData.append("product[specification][General Properties][0][feture]", 'true');
    formData.append("product[specification][General Properties][0][key]", 'Material');
    formData.append("product[specification][General Properties][0][value]", 'Plastic');
    formData.append("product[specification][General Properties][1][feture]", 'false');
    formData.append("product[specification][General Properties][1][key]", 'Weight');
    formData.append("product[specification][General Properties][1][value]", '2kg');

    const requestMessage5 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adddetailSpecificationCall = requestMessage5.messageId;
    requestMessage5.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage5.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage5.id, requestMessage5);
    return true;
  }


  imagesApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[images][]", this.state.pictureFile);
    formData.append("product[primary_image]", this.state.pictureFile);
    formData.append("context", 'images');

    const requestMessage6 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addimagesCall = requestMessage6.messageId;
    requestMessage6.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage6.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage6.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage6.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage6.id, requestMessage6);
    return true;
  }

  documentsApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[documents_attributes][0][title]", this.state.docTitle);
    formData.append("product[documents_attributes][0][document_type]", 'word');
    formData.append("product[documents_attributes][0][file]", this.state.documentFile);
    formData.append("context", 'images');

    const requestMessage7 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.adddocumentsCall = requestMessage7.messageId;
    requestMessage7.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage7.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage7.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage7.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage7.id, requestMessage7);
    return true;
  }

  navigateTo = (route: string) => {
    window.location.href = route;
  
  };

  certificationApi = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("product[certificates_attributes][0][name]", this.state.certificateName);
    formData.append("product[certificates_attributes][0][file]", this.state.qualityFile1);
    formData.append("product[quality_tests_attributes][0][name]", this.state.qualityTestName);
    formData.append("product[quality_tests_attributes][0][file]", this.state.qualityTest1);
    formData.append("context", 'certification');

    const requestMessage8 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addcertificationCall = requestMessage8.messageId;
    requestMessage8.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_productdescription/products/${this.state.productId}`
    );

    requestMessage8.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage8.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage8.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage8.id, requestMessage8);
   this.navigateTo("/ProductList")
    return true;
  }

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    fieldName: keyof S
  ): void => {
    const { value } = event.target;
  
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      errors: { ...prevState.errors, [fieldName]: undefined },
    }));
  };
  
  

  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue });
  }
  handleTabChange1 = (newValue: number): void => {
    this.setState({ value1: newValue });
  }

  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof S
  ): void => {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (file) {
      console.log(`File selected for ${fieldName}:`, file.name);
      this.setState((prevState) => ({
        ...prevState, 
        [fieldName]: file,
        [`${fieldName}Name`]: file.name,

      }));
    }
  };
  handleKeyChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    stateKey: keyof Pick<S, "keys" | "keys1" | "keys2" | "units">
  ) => {
    const updatedArray = [...(this.state[stateKey] as string[])];
    updatedArray[index] = e.target.value;
    this.setState({ [stateKey]: updatedArray } as Pick<S, typeof stateKey>);
  };

  getPhysicalForm = async (method: string) => {
    const header = {

      token: await getStorageData("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getphysicalformApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getphysicalformEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProductType = async (method: string) => {
    const header = {
      token: await getStorageData("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getproducttypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getproducttypeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Customizable Area End
}
