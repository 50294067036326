Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.httpGetMethod = 'GET';
exports.productListApiContentType = 'application/json';
exports.getProductListApiEndPoint = 'bx_block_productdescription/products/get_all_products';
exports.getCategoryApiEndPoint = 'bx_block_categories/get_categories';
exports.getSubCategoryApiEndPoint = 'bx_block_categories/get_sub_categories';
exports.getUpidApiEndPoint = "bx_block_productdescription/upids/get_upid_listings"
exports.getUpidVersionApiEndPoint = "bx_block_productdescription/upids/get_upid_versions"
exports.getBrandsEndPoints ='bx_block_catalogue/brands';

exports.postMethod='POST';
exports.addProductApiContentType = 'application/json';
exports.addProductApiEndPoint ='bx_block_productdescription/products/create_product';

exports.postMethod='POST';
exports.addUPIDApiContentType = 'application/json';
exports.addUPIDApiEndPoint ='bx_block_productdescription/upids';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getBrandsEndPoint ='bx_block_categories/get_categories';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getproducttypeEndPoint ='bx_block_productdescription/product_types';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getphysicalformEndPoint ='bx_block_productdescription/physical_forms';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getindustrymarketEndPoint ='bx_block_productdescription/industry_markets';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getapplicationgroupEndPoint ='bx_block_productdescription/application_groups';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getapplicationEndPoint ='bx_block_productdescription/product_applications';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getlogisticalEndPoint ='bx_block_productdescription/logistical_codes';

exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getsubcategoryEndPoint ='bx_block_categories/get_sub_categories?parent_category_id=158';

// exports.postMethod='PATCH';
exports.addfeatureApiContentType = 'application/json';
exports.addfeatureApiEndPoint ='bx_block_productdescription/products/13';

exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getCompanyApiMethodType = "GET";

// Customizable Area End

