import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableContainer,
  Tabs,
  Tab,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { downImage, saveImg} from "./assets";

import AddUPIDController, {
  Props,
} from "./AddUPIDController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete } from "@mui/material";
// Customizable Area End

export default class AddUPID extends AddUPIDController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  /*istanbul ignore next*/
  AddUPID = () => {
    const tabData = [
      { label: 'UPID vesion data', value: 0 },
      { label: 'Product Data', value: 1 },
      { label: 'Detailed Specifications', value: 2 },
      { label: 'Product taxation', value: 3 },
      { label: 'Units Data', value: 4 },
      { label: 'Applications', value: 5 },
      { label: 'Logistical Code', value: 6 },
    ];

    const tabData1 = [
      { label: 'Brand Details', value1: 0 },
      { label: 'General Properties', value1: 1 },
      { label: 'Physical Properties', value1: 2 },
    ];

    const options = [
      {
          label: "AAC Blocks 4”",
          size: "Size: 625x240x100mm Make: Alpiine",
      },
  ];

  const renderTabContent = () => {
    switch (this.state.value) {
      case 0:
        return renderUPIDdata();
      case 1:
        return renderProductData();
      case 2:
        return renderOtherTabs();
      case 3:
        return renderProductTaxation();
      case 4:
        return renderUnitData();
      case 5:
        return renderApplication();  
      default:
          return renderLogistical();  
    }
  };

  const renderTabContent1 = () => {
    switch (this.state.value1) {
      case 0:
        return renderBrandDetails1();
      case 1:
        return renderGeneralProp();
      default:
        return renderPhysicalProp();
    }
  };      

  

    const renderUPIDdata = () => {
      return (
        <>
          <Box style={{ padding: "20px" , borderBottom: "1px solid #E1E7EF" }}>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>PIM Product Identifier(UPID)</Typography>
              </Box>            
            <TextField
                placeholder="Enter Product Identifier"
                value={this.state.upid}
                onChange={(e) => this.handleChange(e, "upid")}
                inputProps={{
                  'data-test-id': 'upid-name-input',
                  style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                }}   
                style={{
                  ...webStyle.formField, width: "100%", height: "40px", borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                }}
              />
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Version Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                 value={this.state.productVersionName}
                 onChange={(e) => this.handleChange(e, "productVersionName")}
                  placeholder="Enter Product Version Name"
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#BCC1CA",
                      fontFamily: "Inter, sans-serif",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Alias</Typography>
                </Box>
                <TextField
                 value={this.state.alias}
                  placeholder="Enter Alias"
                onChange={(e) => this.handleChange(e, "alias")}
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: {
                      color: "#BCC1CA",
                      fontWeight: 400,
                      fontSize: "16px",
                      fontFamily: "Inter, sans-serif",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", color: "#1E293B", fontFamily: "Inter, sans-serif", },}}
                />
              </Box>
            </Box>
            <Box style={webStyle.formGrid}>
            <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product category</Typography><span style={{  fontWeight: 700,color: "#DC2626", marginTop : "2px" }}>*</span>
                </Box>
                <Autocomplete
  disablePortal
  options={this.state.brandNamesList}
  getOptionLabel={(option) => option.name}
  id="catalogue-field"
  renderInput={(params) => (
    <TextField
      {...params}
      value=""
      placeholder="Select Category"
      InputProps={{
        disableUnderline: true,
        ...params.InputProps,
        style: {
          fontSize: "14px",
          fontFamily: "Inter, sans-serif",
          padding: "6px 8px",
          color: "#1E293B",
          height: "40px",
          background: "#F3F4F6",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
          src={downImage}
            alt="Dropdown Icon"
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          fontSize: "16px", 
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      maxHeight: "200px",
      border: "1px solid #F3F4F6",
      borderRadius: "8px",
    },
  }}
/>
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Subcategory</Typography>
                </Box>
                <Autocomplete
  disablePortal
  options={this.state.brandNamesList1}
  getOptionLabel={(option) => option.name}
  id="catalogue-field"
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Subcategory"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          color: "#1E293B",
          fontSize: "14px",
          fontFamily: "Inter, sans-serif",
          padding: "6px 8px",
          background: "#F3F4F6",
          borderRadius: "8px",
          height: "40px",
        },
        endAdornment: (
          <img
          src={downImage}
            alt="Dropdown Icon"
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
          fontWeight  :400,
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      boxShadow: "none",
      borderRadius: "8px",
      border: "1px solid #F3F4F6",
    },
  }}
/>
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Short Description</Typography>
              </Box>            
            <TextField
                placeholder="Enter Product Description"
                value={this.state.productDescription}
                onChange={(e) => this.handleChange(e, "productDescription")}
                inputProps={{
                  style: { fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",fontSize: "16px",  },
                }}   
                style={{
                  ...webStyle.formField, width: "100%", borderRadius: "8px", height: "40px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px",  fontFamily: "Inter, sans-serif", color: "#1E293B",fontSize: "14px", },
                }}
              />
            </Box>
          </Box>
          <Box onClick={() => this.setState({ value: 1 })} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderUnitData = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
            <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Unit Of Measurement</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={options}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Unit Of Measurement"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          fontSize: "14px",
          padding: "6px 8px",
          borderRadius: "8px",
          height: "40px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
            src={downImage}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          fontStyle: "Inter,sans-serif", 
          fontSize: "16px", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      border: "1px solid #F3F4F6",
      boxShadow: "none",
      borderRadius: "8px",
    },
  }}
/>
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Secondary Unit Of Measurement</Typography>
                </Box>
                <Autocomplete
  disablePortal
  options={options}
  id="catalogue-field"
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Secondary Unit Of Measurement"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          fontSize: "14px",
          padding: "6px 8px",
          borderRadius: "8px",
          height: "40px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
          style={{ width: "12px", height: "7px", marginRight: "8px" }}
            alt="Dropdown Icon"
            src={downImage}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontWeight  :400,
          color: "#BCC1CA", 
          fontStyle: "Inter,sans-serif", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      borderRadius: "8px",
      boxShadow: "none",
      border: "1px solid #F3F4F6",
    },
  }}
/>
              </Box>
            </Box>
            <Box>
              <Typography style={{...webStyle.Title}}>
              Unit Conversion
              </Typography>
            </Box>
            <Box style={webStyle.formGrid3}>
            <Box style={{ ...webStyle.formItem}}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Quantity</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
              </Box>            
            <TextField
                placeholder="Enter Quantity"
                value={this.state.quantity}
                onChange={(e) => this.handleChange(e, "quantity")}
                inputProps={{
                  style: {fontSize: "16px", color: "#BCC1CA", fontFamily: "Inter, sans-serif",   fontWeight: 400, },
                }}   
                style={{
                  ...webStyle.formField, height: "40px", borderRadius: "8px", width: "100%",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px", fontFamily: "Inter, sans-serif", },
                }}
              />
            </Box>
    <Box style={webStyle.formItem}>
      <Box style={{ display: "flex" }}>
        <Typography style={webStyle.label}>Unit</Typography><span style={{  fontWeight: 700, marginTop : "2px",color: "#DC2626", }}>*</span>
      </Box>
      <Autocomplete
        options={options}
        disablePortal
        id="catalogue-field"
        renderInput={(params) => (
          <TextField
            {...params}
            value=""
            placeholder="Enter Unit"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                color: "#1E293B",
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
                padding: "6px 8px",
                height: "40px",
                background: "#F3F4F6",
                borderRadius: "8px",
              },
              endAdornment: (
                <img
                  alt="Dropdown Icon"
                  style={{ width: "12px", height: "7px", marginRight: "8px" }}
                  src={downImage}
                />
              ),
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                fontSize: "16px",
                color: "#BCC1CA",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
              },
            }}
          />
        )}
        sx={{
          "& .MuiAutocomplete-paper": {
            maxHeight: "200px",
            borderRadius: "8px",
            boxShadow: "none",
            border: "1px solid #F3F4F6",
          },
        }}
      />
    </Box>
    <Box style={webStyle.formItem}>
      <Box style={{ display: "flex" }}>
        <Typography style={webStyle.label}><br/></Typography>
      </Box>
      <TextField
          value={this.state.operators}
          onChange={(e) => this.handleChange(e, "operators")}
         placeholder=""
        inputProps={{
          style: {
            fontWeight: 400,
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            color: "#BCC1CA",
          },
        }}
        style={{
          ...webStyle.formField,
          width: "100%",
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            padding: "6px 8px",
            color: "#1E293B",
            fontSize: "14px",
            fontFamily: "Inter, sans-serif",
          },
        }}
      />
    </Box>
    <Box style={webStyle.formItem}>
      <Box style={{ display: "flex" }}>
        <Typography style={webStyle.label}>Quantity</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
      </Box>
      <TextField
        value={this.state.quantity1}
        placeholder="Enter Quantity"
        onChange={(e) => this.handleChange(e, "quantity1")}
        inputProps={{
          style: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#BCC1CA",
            fontFamily: "Inter, sans-serif",
          },
        }}
        style={{
          ...webStyle.formField,
          width: "100%",
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "14px",
            fontFamily: "Inter, sans-serif",
            color: "#1E293B",
            padding: "6px 8px",
          },
        }}
      />
    </Box>
    <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Unit</Typography><span style={{  fontWeight: 700,color: "#DC2626", marginTop : "2px" }}>*</span>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={options}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Enter Unit"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          fontSize: "14px",
          padding: "6px 8px",
          borderRadius: "8px",
          background: "#F3F4F6",
          height: "40px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
            src={downImage}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontWeight  :400,
          color: "#BCC1CA", 
          fontStyle: "Inter,sans-serif", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      borderRadius: "8px",
      maxHeight: "200px",
    },
  }}
/>
              </Box>
            </Box>
            
          </Box>
          <Box onClick={() => this.setState({ value: 5 })} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };
    
    const renderApplication = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
            <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Industry/Market</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={this.state.brandNamesList4}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Industry/Market"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          fontSize: "14px",
          height: "40px",
          borderRadius: "8px",
          padding: "6px 8px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontStyle: "Inter,sans-serif", 
          fontWeight  :400,
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      maxHeight: "200px",
      borderRadius: "8px",
    },
  }}
/>
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Application Group</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={this.state.brandNamesList5}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Application Group"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          fontSize: "14px",
          padding: "6px 8px",
          background: "#F3F4F6",
          height: "40px",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          fontSize: "16px", 
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      borderRadius: "8px",
      boxShadow: "none",
      border: "1px solid #F3F4F6",
    },
  }}
/>
              </Box>
            </Box>
            <Box style={webStyle.formGrid}>
            <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Application</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={this.state.brandNamesList6}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Application"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          fontSize: "14px",
          height: "40px",
          borderRadius: "8px",
          padding: "6px 8px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          fontSize: "16px", 
          color: "#BCC1CA", 
          fontStyle: "Inter,sans-serif", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      borderRadius: "8px",
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      maxHeight: "200px",
    },
  }}
/>
              </Box>
</Box>
            
          </Box>
          <Box onClick={() => this.setState({ value: 6 })} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderLogistical = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
            <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Logistical Code</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={this.state.brandNamesList7}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Logistical Code"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          padding: "6px 8px",
          fontSize: "14px",
          height: "40px",
          background: "#F3F4F6",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          color: "#BCC1CA", 
          fontStyle: "Inter,sans-serif", 
          fontSize: "16px", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      borderRadius: "8px",
      border: "1px solid #F3F4F6",
      maxHeight: "200px",
    },
  }}
/>
              </Box>
</Box>
            
          </Box>
          <Box onClick={this.postAddUPIDApi} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderProductTaxation = () => {
      return (
        <>
          <Box style={{ padding: "20px"}}>
          <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>HSN Code Number</Typography>
              </Box>            
            <TextField
                placeholder="Enter HSN Code Number"
                value={this.state.hsncode}
                onChange={(e) => this.handleChange(e, "hsncode")}
                inputProps={{
                  style: { fontWeight: 400,   color: "#BCC1CA",fontFamily: "Inter, sans-serif",fontSize: "16px",  },
                }}   
                style={{
                  ...webStyle.formField, borderRadius: "8px", width: "100%", height: "40px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", fontSize: "14px",  color: "#1E293B",fontFamily: "Inter, sans-serif", },
                }}
              />
            </Box> 
          </Box>  
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Heading</Typography>
                </Box>
                <TextField
                    value={this.state.heading}
                    onChange={(e) => this.handleChange(e, "heading")}
                  placeholder="Enter Heading"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}        
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Sub Heading</Typography>
                </Box>
                <TextField
                  value={this.state.subheading}
                  onChange={(e) => this.handleChange(e, "subheading")}
                  placeholder="Enter Sub Heading"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Product Description (80 letters)</Typography>
              </Box>            
            <TextField
                placeholder="Enter Product Description"
                value={this.state.productDescription1}
                onChange={(e) => this.handleChange(e, "productDescription1")}
                inputProps={{
                  style: { fontWeight: 400,fontSize: "16px",  color: "#BCC1CA",fontFamily: "Inter, sans-serif",   },
                }}   
                style={{
                  ...webStyle.formField, height: "40px", width: "100%", borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { fontFamily: "Inter, sans-serif", fontSize: "14px", padding: "6px 8px", color: "#1E293B", },
                }}
              />
            </Box>
            <Box style={webStyle.formGrid2}>
    <Box style={{ ...webStyle.formItem, flex: 1 }}>
      <Box style={{ display: "flex" }}>
        <Typography style={webStyle.label}>CGST</Typography>
      </Box>
      <TextField
          value={this.state.cgst}
          onChange={(e) => this.handleChange(e, "cgst")}
        placeholder="Enter CGST"
        inputProps={{
          style: {
            fontWeight: 400,
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            color: "#BCC1CA",
          },
        }}
        style={{
          ...webStyle.formField,
          width: "100%", 
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "14px",
            padding: "6px 8px",
            color: "#1E293B",
            fontFamily: "Inter, sans-serif",
          },
        }}
      />
    </Box>
    <Box style={{ ...webStyle.formItem, flex: 1 }}>
      <Box style={{ display: "flex" }}>
        <Typography style={webStyle.label}>SGST</Typography>
      </Box>
      <TextField
          value={this.state.sgst}
          onChange={(e) => this.handleChange(e, "sgst")}
        placeholder="Enter SGST"
        inputProps={{
          style: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#BCC1CA",
            fontFamily: "Inter, sans-serif",
          },
        }}
        style={{
          ...webStyle.formField,
          width: "100%", 
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "14px",
            fontFamily: "Inter, sans-serif",
            padding: "6px 8px",
            color: "#1E293B",
          },
        }}
      />
    </Box>
    <Box style={{ ...webStyle.formItem, flex: 1 }}>
      <Box style={{ display: "flex" }}>
        <Typography style={webStyle.label}>IGST</Typography>
      </Box>
      <TextField
          value={this.state.igst}
          onChange={(e) => this.handleChange(e, "igst")}
        placeholder="Enter IGST"
        inputProps={{
          style: {
            fontSize: "16px",
            color: "#BCC1CA",
            fontWeight: 400,
            fontFamily: "Inter, sans-serif",
          },
        }}
        style={{
          ...webStyle.formField,
          width: "100%", 
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "14px",
            fontFamily: "Inter, sans-serif",
            padding: "6px 8px",
            color: "#1E293B",
          },
        }}
      />
    </Box>
  </Box>
            </Box>
          <Box onClick={() => this.setState({ value: 4 })} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderProductData = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
            <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Type</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={this.state.brandNamesList2}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Product Type"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          fontSize: "14px",
          padding: "6px 8px",
          color: "#1E293B",
          height: "40px",
          background: "#F3F4F6",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontWeight  :400,
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      maxHeight: "200px",
      borderRadius: "8px",
    },
  }}
/>
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Physical Form</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={this.state.brandNamesList3}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Physical Form"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          color: "#1E293B",
          fontSize: "14px",
          fontFamily: "Inter, sans-serif",
          padding: "6px 8px",
          background: "#F3F4F6",
          height: "40px",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          fontStyle: "Inter,sans-serif", 
          fontSize: "16px", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      borderRadius: "8px",
      border: "1px solid #F3F4F6",
      boxShadow: "none",
    },
  }}
/>
              </Box>
            </Box>

            <Box style={{...webStyle.formGrid, marginTop : "20px"}}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Material Composition</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <TextField
                    value={this.state.materialcomp}
                    onChange={(e) => this.handleChange(e, "materialcomp")}
                  placeholder="Enter Alternative Contact Information"
                  inputProps={{
                    style: {fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif",  color: "#BCC1CA",  },
                  }}                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif",color: "#1E293B", },}}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Sample Availability</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop : "2px" }}>*</span>
                </Box>
                <RadioGroup
          aria-label="support-contact"
          name="supportContact"
          value={this.state.supportContact}
          onChange={this.handleRadioChange}
          row 
        >
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Yes"
            style={{
              fontFamily: "Inter, sans-serif", 
              fontSize: "14px", 
              color: "#1E293B", 
            }}
          />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="No"
            style={{
              fontSize: "14px", 
              color: "#1E293B", 
              fontFamily: "Inter, sans-serif", 
            }}
          />
        </RadioGroup>
              </Box>
            </Box>
          </Box>
          <Box onClick={() => this.setState({ value: 2 })} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBrandDetails1 = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid4}>
            {Array.from({ length: 8 }).map((_, index) => (
      <React.Fragment key={index}>
    <Box style={webStyle.formItem}>
    {index === 0 && (
            <Box style={{ display: "flex" }}>
              <Typography style={webStyle.label}>Feature</Typography>
            </Box>
          )}
          {index !== 0 && (
            <Box style={{ display: "flex" }}>
              <Typography style={webStyle.label}><br/></Typography>
            </Box>
          )}
          <TextField
              onChange={(e) => this.handleChange(e, "feature")}
              value={this.state.feature}
              placeholder=""
            inputProps={{
              style: {
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                fontSize: "16px",
                color: "#BCC1CA",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "14px",
                color: "#1E293B",
                fontFamily: "Inter, sans-serif",
                padding: "6px 8px",
              },
            }}
          />
        </Box>

        <Box style={webStyle.formItem}>
          <Box style={{ display: "flex" }}>
            <Typography style={webStyle.label}>Key</Typography>
          </Box>
          <TextField
            value={this.state.key}
            placeholder="Enter Key"
            onChange={(e) => this.handleChange(e, "key")}
            inputProps={{
              style: {
                fontFamily: "Inter, sans-serif",
                fontWeight: 400,
                color: "#BCC1CA",
                fontSize: "16px",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                padding: "6px 8px",
                fontFamily: "Inter, sans-serif",
                color: "#1E293B",
                fontSize: "14px",
              },
            }}
          />
        </Box>
        <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Value</Typography>
                </Box>
                <Autocomplete
  disablePortal
  options={options}
  id="catalogue-field"
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Value"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          fontSize: "14px",
          color: "#1E293B",
          padding: "6px 8px",
          height: "40px",
          background: "#F3F4F6",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          color: "#BCC1CA", 
          fontWeight  :400,
          fontStyle: "Inter,sans-serif", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      maxHeight: "200px",
      borderRadius: "8px",
    },
  }}
/>
              </Box>
      </React.Fragment>
    ))}
</Box>
            
          </Box>
        </>
      );
    };

    const renderGeneralProp = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid4}>
            {Array.from({ length: 10 }).map((_, index) => (
      <React.Fragment key={index}>
    <Box style={webStyle.formItem}>
    {index !== 0 && (
            <Box style={{ display: "flex" }}>
              <Typography style={webStyle.label}><br/></Typography>
            </Box>
          )}
    {index === 0 && (
            <Box style={{ display: "flex" }}>
              <Typography style={webStyle.label}>Feature</Typography>
            </Box>
          )}
          <TextField
            placeholder=""
            onChange={(e) => this.handleChange(e, "feature")}
            value={this.state.feature}
            inputProps={{
              style: {
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                color: "#BCC1CA",
                fontSize: "16px",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                padding: "6px 8px",
                color: "#1E293B",
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
              },
            }}
          />
        </Box>

        <Box style={webStyle.formItem}>
          <Box style={{ display: "flex" }}>
            <Typography style={webStyle.label}>Key</Typography>
          </Box>
          <TextField
              placeholder="Enter Key"
              value={this.state.key}
              onChange={(e) => this.handleChange(e, "key")}
            inputProps={{
              style: {
                fontSize: "16px",
                fontWeight: 400,
                color: "#BCC1CA",
                fontFamily: "Inter, sans-serif",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
                padding: "6px 8px",
                color: "#1E293B",
              },
            }}
          />
        </Box>
        <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Value</Typography>
                </Box>
                <Autocomplete
  disablePortal
  options={options}
  id="catalogue-field"
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Value"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          color: "#1E293B",
          fontFamily: "Inter, sans-serif",
          fontSize: "14px",
          padding: "6px 8px",
          height: "40px",
          borderRadius: "8px",
          background: "#F3F4F6",
        },
        endAdornment: (
          <img
          src={downImage}
            alt="Dropdown Icon"
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontWeight  :400,
          fontSize: "16px", 
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      maxHeight: "200px",
      borderRadius: "8px",
      boxShadow: "none",
      border: "1px solid #F3F4F6",
    },
  }}
/>
              </Box>
      </React.Fragment>
    ))}
</Box>
            
          </Box>
        </>
      );
    };

    const renderPhysicalProp = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid5}>
            {Array.from({ length: 15 }).map((_, index) => (
      <React.Fragment key={index}>
    <Box style={webStyle.formItem}>
    {index===0 && (
            <Box style={{ display: "flex" }}>
              <Typography style={webStyle.label}>Feature</Typography>
            </Box>
          )}
          {index !== 0 && (
            <Box style={{ display: "flex" }}>
              <Typography style={webStyle.label}><br></br></Typography>
            </Box>
          )}
          <TextField
              value={this.state.feature}
              placeholder=""
              onChange={(e) => this.handleChange(e, "feature")}
            inputProps={{
              style: {
                fontSize: "16px",
                fontWeight: 400,
                color: "#BCC1CA",
                fontFamily: "Inter, sans-serif",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
                color: "#1E293B",
                padding: "6px 8px",
              },
            }}
          />
        </Box>

        <Box style={webStyle.formItem}>
          <Box style={{ display: "flex" }}>
            <Typography style={webStyle.label}>Key</Typography>
          </Box>
          <TextField
              onChange={(e) => this.handleChange(e, "key")}
              value={this.state.key}
            placeholder="Enter Key"
            inputProps={{
              style: {
                color: "#BCC1CA",
                fontFamily: "Inter, sans-serif",
                fontWeight: 400,
                fontSize: "16px",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "14px",
                color: "#1E293B",
                fontFamily: "Inter, sans-serif",
                padding: "6px 8px",
              },
            }}
          />
        </Box>
        <Box style={webStyle.formItem}>
          <Box style={{ display: "flex" }}>
            <Typography style={webStyle.label}>Unit</Typography>
          </Box>
          <TextField
            placeholder="Enter Unit"
            onChange={(e) => this.handleChange(e, "unit")}
            value={this.state.unit}
            inputProps={{
              style: {
                fontSize: "16px",
                fontWeight: 400,
                color: "#BCC1CA",
                fontFamily: "Inter, sans-serif",
              },
            }}
            style={{
              ...webStyle.formField,
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                padding: "6px 8px",
                fontSize: "14px",
                fontFamily: "Inter, sans-serif",
                color: "#1E293B",
              },
            }}
          />
        </Box>
        <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Value</Typography>
                </Box>
                <Autocomplete
  disablePortal
  id="catalogue-field"
  options={options}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select Value"
      value=""
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        style: {
          fontFamily: "Inter, sans-serif",
          color: "#1E293B",
          padding: "6px 8px",
          height: "40px",
          background: "#F3F4F6",
          fontSize: "14px",
          borderRadius: "8px",
        },
        endAdornment: (
          <img
            alt="Dropdown Icon"
            src={downImage}
            style={{ width: "12px", height: "7px", marginRight: "8px" }}
          />
        ),
      }}
      inputProps={{
        ...params.inputProps,
        style: {
          fontSize: "16px", 
          fontWeight  :400,
          fontStyle: "Inter,sans-serif", 
          color: "#BCC1CA", 
        },
      }}
    />
  )}
  sx={{
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
      border: "1px solid #F3F4F6",
      borderRadius: "8px",
      maxHeight: "200px",
    },
  }}
/>
              </Box>
      </React.Fragment>
    ))}
</Box>
            
          </Box>
        </>
      );
    };


       const renderOtherTabs = () => {
      return (
        <>
         <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value1} onChange={(event, newValue) => this.handleTabChange1(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData1.map(({ label, value1 }) => (
                  <Tab
                    key={value1}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography
                        style={this.state.value1 === value1 ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
            {renderTabContent1()} 
            </Table>
          </TableContainer>
        </Box>
      </Box>
             <Box onClick={() => this.setState({ value: 3 })} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
          </>
      );
    };

    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography style={webStyle.Headingss}>
            Add UPID Details
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
            {renderTabContent()} 
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar   />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.AddUPID()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainContainer: {
    marginLeft: "30px",
    fontFamily: "Inter, sans-serif",
    marginTop:"20px"
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Inter, sans-serif",
    alignItems: "center",
    margin: "20px 0px",
  },
  Headingss: {
    fontFamily: "Inter, sans-serif",
    lineHeight: "1.5",
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "left" as React.CSSProperties["textAlign"]
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "start",
    gap: "20px",
  },
  formGrid2:{
    display:"grid",
    gap: "15px",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "start",
  },
  formGrid3:{
    display:"grid",
    alignItems: "start",
    gridTemplateColumns: "3fr 3fr 1fr 3fr 3fr",
    gap: "16px",
  },
  formGrid4:{
    display:"grid",
    gap: "16px",
    gridTemplateColumns: "1fr 8fr 8fr",
    alignItems: "start",
  },
  formGrid5:{
    display:"grid",
    gap: "16px",
    alignItems: "start",
    gridTemplateColumns: "1fr 5fr 5fr 5fr",
  },

    formItem: {
      display: 'flex' as 'flex',  
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',  
    width: '100%' as string, 
  },
  AddType:{
    fontFamily : "Inter, sans-serif",
    fontSize : "16px",
    marginLeft : "8px",
    fontWeight : 700,
    lineHeight : "24px",
    color : "#FF6600",
  },
  formField: {
    background: '#F3F4F6',
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    boxSizing: 'border-box' as 'border-box',  
  } as React.CSSProperties,  
  label: {
    marginBottom: "5px",
    marginTop:"10px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
  },
  textFieldStyle: {
    fontWeight: 400,
    backgroundColor: "#F3F4F6",
    fontSize: "14px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
  },
  uploadContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  uploadButton: {
    backgroundColor: "#ff6600",
    color: "#fff",
    padding: "6px 12px",
    borderRadius: "8px",
    textTransform: "none",
  },
  saveButton: {
    boxShadow : "none",
    margin : "16px 20px 16px 0px",
  },
  formHeading: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "20px",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    fontWeight: 500,
    height: "44px",
    width: "max-content",
  },
  navTabContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "58px",
    justifyContent: "space-between",
    borderBottom: "1px solid #E1E7EF"
  },
  activeTab: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '12px',
    textAlign: 'center' as 'center',  
    color: '#EE6900',
  },
  inactiveTab: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left' as 'left', 
    fontFamily: 'Inter, sans-serif',
    color: '#565E6C',
  },
  Title:{
    fontSize: '20px',
    fontWeight: 600,
    marginBottom : "20px",
    fontFamily: 'Inter, sans-serif',
    marginTop:"20px"
  }
}
// Customizable Area End