import React from "react";
import { styled } from "@mui/material/styles";

// Customizable Area Start
import {
  Typography,
  Box,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Radio,
} from "@material-ui/core";
import { sortingIcon, img_, seacrhIcon, filterIcon, EditImg, GotoImg, Delete_Img } from "../../user-profile-basic/src/assets";

import ProductListController, {
  Props,
} from "./ProductListController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
import zIndex from "@material-ui/core/styles/zIndex";
// Customizable Area End

export default class ProductList extends ProductListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  /*istanbul ignore next*/
  Producttable = () => {

    console.log("hello", this.state.ProductData)
    const tabData = [
      { label: 'All Results', value: 0 },
    ];

    const formattedProductData = this.state.ProductData.map((brand: any) => {
      // Convert the 'updated_at' field to a Date object and format it
      const updatedAt = new Date(brand.attributes.updated_at);

      // Format the date into 'DD MMM YYYY' format (e.g., '09 Feb 2024')
      const formatterDate = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      // Format the time into 'hh:mm A' format (e.g., '07:23 PM')
      const formatterTime = new Intl.DateTimeFormat("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const formattedDate = formatterDate.format(updatedAt);
      const formattedTime = formatterTime.format(updatedAt);

      return {
        ...brand,
        formattedDate,
        formattedTime,
      };
    });



    const brandkeys = Object.keys(this.state.selectedBrands);
    const half = Math.ceil(brandkeys.length / 2);
    const leftBrands = brandkeys.slice(0, half);
    const rightBrands = brandkeys.slice(half);


    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography variant="h6" style={webStyle.Headingss}>
            Products
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.NavTab }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={0} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px", },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ height: "58px", textTransform: "none", }}
                    label={
                      <Typography style={webStyle.ActiveTabs}>
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box style={webStyle.SearchContainers}>
              <InputBase
                placeholder="Search"
                style={webStyle.SearchInputs}
                inputProps={{
                  style: {
                    fontFamily: 'inter, sans-serif',
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: 12,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" data-testId="Serach-image" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <IconButton>
                <img src={sortingIcon} onClick={this.handleToggle1} alt="sorting icon" data-testId="MoreVertIcon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
                {this.state.open1 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "85%",
                      right: "0",
                      width: 300,
                      bgcolor: "#FFFFFF",
                      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: 10,
                      p: 0,
                      zIndex: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #DEE1E6",
                        marginBottom: 16,
                        padding: "15px 20px",
                      }}
                    >
                      <img
                        src={sortingIcon}
                        alt="sort icon"
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span style={{ fontSize: "1.25rem", fontWeight: "bold", color: "black" }}>
                        Sort By
                      </span>
                    </div>
                    <Grid container spacing={2} style={{ padding: "0px 50px 20px 30px" }}>
                      <Typography style={{ color: "black", fontWeight: "bold", fontSize: "1.15rem", paddingTop: "10px", paddingBottom: "10px" }}>Modified Date & Time </Typography>
                      <FormGroup>
                        <FormControlLabel style={{ color: "black" }}
                          control={
                            <Radio
                              checked={this.state.selectedOption === "newest"}
                              onChange={() => this.handleRadioChange("newest")}
                              value="newest"
                              style={{ color: "#FF6600" }}
                              data-testid="newest-radio"
                            />
                          }
                          label="Newest to Oldest"
                        />
                        <FormControlLabel style={{ color: "black" }}
                          control={
                            <Radio
                              checked={this.state.selectedOption === "oldest"}
                              onChange={() => this.handleRadioChange("oldest")}
                              value="oldest"
                              style={{ color: "#FF6600" }}
                              data-testid="oldest-radio"
                            />
                          }
                          label="Oldest to Newest"
                        />
                      </FormGroup>
                    </Grid>
                  </Box>
                )}
              </IconButton>
              <IconButton>
                <img src={filterIcon} onClick={this.handleToggle} alt="filter icon" data-testId="filterIcons" style={{ width: 20, height: 20 }} />
                {this.state.open && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "85%",
                      right: "95%",
                      width: 450,
                      bgcolor: "#FFFFFF",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      p: 0,
                      borderRadius: 2,
                      zIndex: 5
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: 16, paddingLeft: "15px", paddingTop: "30px" }}>
                      <img
                        src={filterIcon}
                        alt="filter icon"
                        data-testId="filterIcons"
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span style={{ fontSize: "1.25rem", fontWeight: "bold", color: "black" }}>FILTER</span>
                    </div>
                    <hr style={{ border: 0, borderTop: "1px solid #DEE1E6", marginBottom: 16 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                        <FormGroup>
                          {leftBrands.map((brand) => (
                            <FormControlLabel style={{ color: "black" }}
                              key={brand}
                              control={
                                <Checkbox
                                  checked={this.state.selectedBrands[brand]}
                                  onChange={() => this.handleCheckboxChange(brand)}
                                  style={{
                                    color: "#FF6600",
                                  }}
                                />
                              }
                              label={brand}
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={6} style={{ paddingRight: "50px", paddingLeft: "15px" }}>
                        <FormGroup>
                          {rightBrands.map((brand) => (
                            <FormControlLabel style={{ color: "black" }}
                              key={brand}
                              control={
                                <Checkbox
                                  checked={this.state.selectedBrands[brand]}
                                  onChange={() => this.handleCheckboxChange(brand)}
                                  style={{
                                    color: "#FF6600",
                                  }}
                                />
                              }
                              label={brand}
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <hr style={{ border: 0, borderTop: "1px solid #DEE1E6", marginBottom: 16 }} />
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16, padding: "20px" }}>
                      <Button onClick={this.handleClearAll} style={{ backgroundColor: "#FFF6F0", color: "#FF6600", paddingLeft: "35px", paddingRight: "35px", borderRadius: "15px" }}>
                        Clear All
                      </Button>
                      <Button
                        onClick={this.handleApply}
                        variant="contained"
                        style={{ backgroundColor: "#FF6600", color: "#fff", paddingLeft: "55px", paddingRight: "55px", borderRadius: "15px" }}
                      >
                        Apply
                      </Button>
                    </div>
                  </Box>
                )}
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...webStyle.TableHeads }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                    Product
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                    Brand Name
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                    Modified Date and Time
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                    Actions
                  </TableCell>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={webStyle.TBodys}>
                <TableRow style={{ height: "30px" }}></TableRow>
                {formattedProductData.map((brand: any, index: any) => (
                  <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                    <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                    <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <img
                            src={brand.attributes?.primary_image?.url || img_}
                            alt={"logo"}
                            style={{
                              height: "50px",
                              width: "40px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                          <b>{brand.attributes.brand_product_name || "Product Name Not Available"}</b>
                          <br />
                          {brand.attributes.length_in_inch && brand.attributes.width_in_inch && brand.attributes.inlet_size ? (
                            <span style={{ color: "#323842", fontFamily: "inter", fontWeight: "normal" }}>Size: {brand.attributes.length_in_inch}x{brand.attributes.width_in_inch}x{brand.attributes.inlet_size} mm</span>
                          ) : (
                            <span style={{ color: "#323842", fontWeight: "normal", fontFamily: "inter" }}>Size: Not Available</span>
                          )}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.attributes.brand_name}</TableCell>
                    <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>
                      <div>
                        <span style={{ fontSize: "13px", color: "#323842" }}>
                          {brand.formattedDate}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px", color: "#323842" }}>
                          {brand.formattedTime}
                        </span>
                      </div>
                    </TableCell>                    <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
                      <IconButton
                        aria-label="more"
                        onClick={this.handleMenuClick}
                        data-testid="MoreIcon"


                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        PaperProps={{
                          style: { boxShadow: 'none', width: "150px", border: "1px solid #E7E2Ef", },
                        }}
                      >
                        <MenuItem onClick={()=>this.navigateToProductCatalogue('/AddProduct')} data-testId="EditMenuItem" ><img src={EditImg} alt="editicon" style={{ paddingRight: '10px' }} />Edit SKU</MenuItem>
                        <MenuItem onClick={()=>this.navigateToProductCatalogue('/AddUPID')} data-testId="DeleteMenuItem"><img src={GotoImg} alt="gotoicon" style={{ paddingRight: '10px' }} />Go to UPID</MenuItem>
                        <MenuItem data-testId="DeleteMenuItem"><img src={Delete_Img} alt="deleteicon" style={{ paddingRight: '10px' }} />Delete</MenuItem>
                      </Menu>

                    </TableCell>
                    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ width: "1%", borderTop: "none", borderBottom: "none", borderRight: "none", }}></TableCell>
                  <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    <span
      style={{
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        color: "#64748B",
        fontWeight: 400,
      }}
    >
      Showing {this.state.resultperPage * (this.state.currentPage - 1) + 1} -{" "}
      {Math.min(this.state.resultperPage * this.state.currentPage, this.state.totalResults)} of{" "}
      {this.state.totalResults} results
    </span>

    <Pagination
      count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
      page={this.state.currentPage}
      siblingCount={0}
      boundaryCount={1}
      onChange={this.handlePageChange}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          style={{
            textDecoration: "underline",
            borderRadius: 0,
            background: "none",
            fontFamily: "Inter, sans-serif",
            color: "#979797",
            fontSize: "12px",
            fontWeight: 400,
          }}
        />
      )}
    />
  </div>
</TableCell>

                  <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderRight: "none", borderTop: "none", borderBottom: "none" }}></TableCell>
                </TableRow>
                <TableRow style={{ height: "30px" }}></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Producttable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number;
  };
} = {
  MainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
    zIndex: 1
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Inter, sans-serif",
    alignItems: "center",
    margin: "20px 0px",
  },
  Headingss: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "left",
    lineHeight: "26px",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  TBodys: {
    padding: "20px",
  },
  Cells1: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
  },
  Cells: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842",
  },
  TableHeads: {
    backgroundColor: "#f3f4f6",
  },
  TableCellss: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    backgroundColor: "#F3F4F6",
    textAlign: "left",
    color: "#565E6C",
  },
  NavTab: {
    display: "flex",
    justifyContent: "space-between",
    height: "58px",
    alignItems: "center",
    width: "100%",
  },
  SearchContainers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "265px",
  },
  SearchInputs: {
    width: "173px",
    height: "35px",
    borderRadius: "9px",
    fontFamily: "Inter, sans-serif",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "20px",
    textAlign: "left",
  },
  ActiveTabs: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    textAlign: "left",
    color: "#EE6900",
    lineHeight: "20px",
  },
};
// Customizable Area End