export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const penData = require("../assets/pen.jpg");
export const arrow = require("../assets/user_arrow.png");
export const navData = require("../assets/nav.jpg");
export const up_arrow = require("../assets/up_arrow.png");
export const mihir = require("../assets/mihir_img.jpg");
export const vector = require("../assets/company_vectro.jpg");
export const addData = require("../assets/add.jpg");
export const check = require("../assets/check_circle.png");
export const pdfData = require("../assets/image_pdf.png");
export const download = require("../assets/download.jpg");
export const camera = require("../assets/camera.png");
export const uploadIcon = require("../assets/upload-icon.jpg");
export const cart = require("../assets/cart.jpg");
export const truck = require("../assets/local_shipping.png");
export const rupe = require("../assets/rupe.png");
export const list = require("../assets/list.png");
export const account = require("../assets/account.png");
export const imgDown = require("../assets/image-down.jpg");
export const glass = require("../assets/glass.png");
export const downImage = require("../assets/downarrow.svg");
export const FilesImg = require("../assets/files.svg");
export const saveImg = require("../assets/save.svg");
export const AddImg = require("../assets/add.svg");
export const seacrhIcon = require("../assets/search.svg");
export const filterIcon = require("../assets/filter.svg");
export const sortingIcon = require("../assets/sorting.svg");
export const img_ =require("../assets/img_.svg")
export const UploadImg = require("../assets/uplaod.svg");
export const AddImg_=require("../assets/addimage.svg");
export const EditImg=require("../assets/edit_.svg");
export const GotoImg=require("../assets/goto.svg");
export const Delete_Img=require("../assets/delete1.svg");


















