import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  selectedRow:number | null;
  branddetail :{
    brandName: string;
    industrySector: string;
    brandTagline: string;
    brandDescription: string;
    selectedFile: File | null;
  }

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddLogisticalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   anchorEl: null,
   value: 0,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   selectedRow:null,
   branddetail :{
    brandName: "",
    industrySector: "",
    brandTagline: "",
    brandDescription: "",
    selectedFile: null,
   }
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
// Customizable Area End
  }

  // Customizable Area Start
  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue,
                    selectedRow:null,
    });
  }
  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }
  handleRadioClick = (index: number) => {
    this.setState({ selectedRow: index }); 
};

  // Customizable Area End
}
