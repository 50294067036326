import React from "react";

// Customizable Area Start
import {
    Typography,
    Box,
    Table,
    TableContainer,
    Tabs,
    Tab,
    TextField,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Radio,
} from "@material-ui/core";
import { withStyles, WithStyles } from '@mui/styles';
import { AddImg, downImage, FilesImg, saveImg, UploadImg } from "./assets";

import AddLogisticalController, {
    Props,
} from "./AddLogisticalController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete, Pagination, PaginationItem } from "@mui/material";
// Customizable Area End

export default class AddLogistical extends AddLogisticalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    /*istanbul ignore next*/
    Addbrands = () => {
        const tabData = [
            { label: this.state.value === 0 ? 'Product Catagory' : 'PC', value: 0 },
            { label: this.state.value === 1 ? 'Temperature Sensitivity' : 'TS', value: 1 },
            { label: this.state.value === 2 ? 'Environmental Sensitivity' : 'ES', value: 2 },
            { label: this.state.value === 3 ? 'Hazard Classification' : 'HC', value: 3 },
            { label: this.state.value === 4 ? 'Packaging Type' : 'PT', value: 4 },
            { label: this.state.value === 5 ? 'Special Handling Requirements' : 'SHR', value: 5 },
            { label: this.state.value === 6 ? 'Compliance/Permit Requirement' : 'CPR', value: 6 },
            { label: this.state.value === 7 ? 'Accessability/Route Constraints' : 'ARC', value: 7 },
            { label: this.state.value === 8 ? 'Material Unloading Factor' : 'MUF', value: 8 },
            { label: this.state.value === 9 ? 'Stacking Ability' : 'SA', value: 9 },
        ];

        const options = [
            {
                label: "AAC Blocks 4”",
                size: "Size: 625x240x100mm Make: Alpiine",
            },
        ];
        const brandsData = [
            {
                brandName: "Bosch",
                industry: "Electric",
            },
            {
                brandName: "CON",
                industry: "Electricity",
            },
        ];

        const renderTabContent = () => {
            switch (this.state.value) {
                case 0:
                    return renderProductCatagory();
                case 1:
                    return renderTemperature();
                case 2:
                    return renderEnvironment();
                case 3:
                    return renderHazard();
                case 4:
                    return renderPackage();
                case 5:
                    return renderSHR();
                case 6:
                    return renderCPR();
                case 7:
                    return renderARC();
                case 8:
                    return renderMUF();
                default:
                    return renderStack();
            }
        };



        const renderProductCatagory = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio   style={{
                                                     color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}}
                                                  data-testid={`radio-button-${index}`} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                  <div style={{backgroundColor:"#FFFFFF"}}> 
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px",backgroundColor: '#FFFFFF', }}
                                                />
                                                </div> 
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 1,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderHazard = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                <div style={{backgroundColor:"#FFFFFF"}}>   
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div> 
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 4,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderPackage = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                    <div style={{backgroundColor:"#FFFFFF"}}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 5,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderSHR = () => {
            return (
                <>
                   <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                    <div style={{backgroundColor:"#FFFFFF"}}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 6,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderCPR = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                <div style={{backgroundColor:"#FFFFFF"}}>   
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 7,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderARC = () => {
            return (
                <>
                   <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                <div style={{backgroundColor:"#FFFFFF"}}>   
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 8,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderMUF = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                    <div style={{backgroundColor:"#FFFFFF"}}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 9,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderStack = () => {
            return (
                <>
                   <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                    <div style={{backgroundColor:"#FFFFFF"}}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderTemperature = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                    <div style={{backgroundColor:"#FFFFFF"}}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 2,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        const renderEnvironment = () => {
            return (
                <>
                    <Box style={{ borderBottom: "1px solid #E1E7EF" }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ ...webStyle.TableHeads }}>
                                    <TableRow style={{ borderBottom: "none" }}>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Code
                                        </TableCell>
                                        <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                                            Description
                                        </TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={webStyle.TBodys}>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                    {brandsData.map((brand, index) => (
                                        <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                                            <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}> <Box
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                }}
                                            >
                                                <Radio style={{color: this.state.selectedRow === index ? '#FF8533' : 'inherit',}} checked={this.state.selectedRow === index}
                                                    onChange={() => this.handleRadioClick(index)} />
                                                    <div style={{backgroundColor:"#FFFFFF"}}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    defaultValue={brand.brandName}
                                                    style={{ width: "100px" }}
                                                />
                                                </div>
                                            </Box></TableCell>
                                            <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>{brand.industry}</TableCell>
                                            <TableCell align="left" style={{borderTop: "1px solid #E7E2Ef", backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent' }}>
                                            </TableCell>
                                            <TableCell style={{ width: "0%",borderBottom: "1px solid #E7E2Ef",borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF",
                                                backgroundColor: this.state.selectedRow === index ? '#FFF6EE' : 'transparent'
                                             }}></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                        <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                                                <Pagination
                                                    count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                    page={this.state.currentPage}
                                                    // onChange={this.handlePageChange}
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            {...item}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                borderRadius: 0,
                                                                background: "none",
                                                                fontFamily: "Inter, sans-serif",
                                                                fontSize: "12px",
                                                                fontWeight: 400,
                                                                color: "#979797"
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                    <TableRow style={{ height: "30px" }}></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box onClick={() => this.setState({ value: 3,selectedRow:null })} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
                    </Box>
                </>
            );
        };

        return (
            <Box style={{ width: "94%", ...webStyle.MainContainer }}>
                <Box style={webStyle.Headers}>
                    <Typography style={webStyle.Headingss}>
                        Add Logistical Code
                    </Typography>
                </Box>
                <Box style={{ ...webStyle.formGrid, marginTop: "30px", marginBottom: "30px" }}>
                    <Box style={webStyle.formItem}>
                        <Box style={{ display: "flex" }}>
                            <Typography style={webStyle.label}>Product Tag</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                        </Box>
                        <TextField
                            //   onChange={this.handlechange}
                            placeholder="Enter Product Tag"
                            inputProps={{
                                style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                            }} style={{
                                ...webStyle.formField,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                            }}
                        />
                    </Box>
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    <Box style={{ ...webStyle.navTabContainer }}>
                        <Box
                            style={{
                                flexGrow: 1,
                                display: "flex",
                                overflowX: "auto",
                            }}
                        >
                            <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                            }}>
                                {tabData.map(({ label, value }) => (
                                    <Tab
                                        key={value}
                                        style={{ textTransform: "none", height: "58px", minWidth: `${label.length * 9 + 30}px`, }}
                                        label={
                                            <Typography
                                                style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                                            >
                                                {label}
                                            </Typography>
                                        }
                                    />
                                ))}
                            </Tabs>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table aria-label="simple table">
                            {renderTabContent()}
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Usernavbar />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.Addbrands()}
                </SideBar>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    MainContainer: {
        marginLeft: "47px",
        fontFamily: "Inter, sans-serif",
    },
    Headers: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
        fontFamily: "Inter, sans-serif",
    },
    Headingss: {
        fontFamily: "Inter, sans-serif",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "1.5",
        textAlign: "left" as React.CSSProperties["textAlign"]
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        alignItems: "start",
    },
    formItem: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
        width: '100%' as string,
    },
    AddType: {
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FF6600",
        marginLeft: "8px"
    },
    formField: {
        width: '100%',
        height: '40px',
        borderRadius: '8px',
        background: '#F3F4F6',
        boxSizing: 'border-box' as 'border-box',
    } as React.CSSProperties,
    label: {
        marginBottom: "5px",
        fontSize: "14px",
        fontWeight: 700,
        color: "#334155",
    },
    textFieldStyle: {
        fontSize: "14px",
        fontWeight: 400,
        backgroundColor: "#F3F4F6",
        border: "none",
        borderRadius: "8px",
        boxShadow: "none",
    },
    uploadContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    uploadButton: {
        backgroundColor: "#ff6600",
        color: "#fff",
        textTransform: "none",
        padding: "6px 12px",
        borderRadius: "8px",
    },
    saveButton: {
        margin: "16px 20px 16px 0px",
        boxShadow: "none",
    },
    formHeading: {
        fontFamily: "Inter, sans-serif",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "20px",
    },
    CheckoutButton: {
        backgroundColor: "#ff6600",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
    },
    navTabContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "58px",
        borderBottom: "1px solid #E1E7EF"
    },
    activeTab: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center' as 'center',
        color: '#EE6900',
    },
    inactiveTab: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left' as 'left',
        color: '#565E6C',
    },
    Title: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: "20px"
    },
    TBodys: {
        padding: "20px",
    },
    Cells1: {
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842",
    },
    Cells: {
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        color: "#323842",
    },
    TableHeads: {
        backgroundColor: "#f3f4f6",
        width: "100%"
    },
    TableCellss: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
    },
    TableCellss1: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        paddingRight: "250px",
        paddingLeft:"70px"
    } as React.CSSProperties,
    TableCellss2: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        paddingRight: "100px",
    } as React.CSSProperties,
    TableCellss3: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        paddingLeft: "220px",
    } as React.CSSProperties,
}
// Customizable Area End