import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
import {  MouseEvent } from 'react'
interface IndustryItem {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  alertMessage: string;
  industryData: IndustryItem[];
  anchorEl: HTMLElement | null;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  searchText: string;
  industry: string;
  selectedIndustryId:string;
  selectedIndustryName:string;
  IndustryId:any;
  dialogMode:string;
  token: string;
  alertVisible: boolean;
  value: number | 0;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationIndustryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addIndustryApiCallId: any;
  getIndustryApiCallId: any;
  editIndustryApiCallId:any;
  deleteIndustryApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      industryData: [],
      IndustryId:0,
      dialogMode: "",
      alertMessage: "",
      alertVisible: false,
      openDialog: false,
      anchorEl: null,
      industry: "",
      selectedIndustryId:"",
      selectedIndustryName:"",
      token: "",
      searchText: '',
      totalResults: 0, 
      resultperPage: 12, 
      currentPage: 1 ,
      value: 0,
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount?.();
    this.getIndustry();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
        if (apiRequestCallId === this.addIndustryApiCallId) {
          this.handleAddIndustryResponse(responseJson);
        } 
        else if (apiRequestCallId === this.getIndustryApiCallId) {
          this.handleGetIndustryResponse(responseJson);
        }
        else if (apiRequestCallId === this.editIndustryApiCallId) {
          this.handleEditIndustryResponse(responseJson);
        }
        else if (apiRequestCallId === this.deleteIndustryApiCallId) {
          this.handleDeleteIndustryResponse(responseJson);
        }
      }
  }

  handleEditIndustryResponse(responseJson: any) {
    if (responseJson?.product?.data) {
      this.setState({ 
        openDialog: false,
        anchorEl: null,
        industry: "",
      });
      this.getIndustry(); 
      this.showAlert(
        configJSON.successTitle,
        configJSON.successEditIndustry
      );
    } else {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorEditIndustry
      );
    }
  }
  
  handleDeleteIndustryResponse(responseJson: any) {
      this.setState({ 
        openDialog: false, 
        anchorEl: null,
        industry:"",
      });
      this.getIndustry(); 
    
  }
  handleAddIndustryResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ 
        industryData: responseJson?.data, 
        openDialog: false, 
        industry: "" 
      });
      this.getIndustry(); 
    }
  }
  
  handleGetIndustryResponse(responseJson: any) {
    if (responseJson?.data) {
      const { count, items, page } = responseJson.meta?.pagy || {}; 
    
      this.setState({
        industryData: responseJson.data, 
        totalResults: count || 0,       
        resultperPage: items || 12,    
        currentPage: page || 1,       
      });
    }
  }
  handleMenuClick = (event: MouseEvent<HTMLElement>,selectedIndustryId:string,selectedIndustryName:string) => {
    this.setState({ anchorEl: event.currentTarget, IndustryId:selectedIndustryId,industry: selectedIndustryName});

  };

  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };


  handlePageChange = (event: React.ChangeEvent<unknown> | null, value: number) => {
    this.setState({ currentPage: value });
    this.getIndustry();
  };

  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }


  handleComOpen = (mode: string ) => {
    

    this.setState({
      openDialog: true,
      dialogMode: mode, 
     
    });
    
  };
  
  handleClose = () => {
    this.setState({
      openDialog: false,
      anchorEl: null,
      dialogMode: "",
      alertMessage: "",
      alertVisible: false,
      industry:"",
    });
  };
  handleIndustryMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value
    this.setState({ industry: name })
  }

  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)

  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  showAlert = (title: string, message: string) => {
    this.setState({ alertVisible: true, alertMessage: message });
  };
  addNewIdustry = async () => {
   
      let data = {
        industry_market: {
          name: this.state.industry
        }
      };
      const header = {
        "Content-Type": configJSON.industryApiContentType,
        token: await getStorageData('authToken'),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addIndustryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.industryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  getIndustry = async () => {
    const header = {
      "Content-Type": configJSON.industryApiContentType,
      token: await getStorageData("authToken"),
    };
  
    const queryParams = `?page=${this.state.currentPage}&items=${this.state.resultperPage}`;
    const apiEndpoint = `${configJSON.industryGetAPIEndPoint}${queryParams}`;
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getIndustryApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editIndustry = async () => {
  
      let data = {
        industry_market: {
          name: this.state.industry,
        },
      };
  
      const header = {
        "Content-Type": configJSON.industryApiContentType,
        token: await getStorageData("authToken"),
      };
  
      const apiEndpoint = `${configJSON.industryEditAPIEndPoint}/${this.state.IndustryId}`;
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editIndustryApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpUpdateType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };

  deleteIndustry = async () => {
      const header = {
        "Content-Type": configJSON.industryApiContentType,
        token: await getStorageData("authToken"),
      };
  
      const apiEndpoint = `${configJSON.industryDeleteAPIEndPoint}/${this.state.IndustryId}`;
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteIndustryApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };
  
  
   
  // Customizable Area End
}
