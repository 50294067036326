import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {Message} from "../../../framework/src/Message"
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

// Customizable Area Start
interface IndustryItem {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  errors:any;
  license:any;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  industryData: IndustryItem[];
  resultperPage : number;
  currentPage: number;
  brandName: string;
  industrySector: string;
  brandTagline: string;
  brandDescription: string;
  selectedFile: File | null;
  qualityFile1:File;
  qualityFile2:File;
  qualityFile3:File;
  qualityFile2Name:string;
  qualityFile3Name:string;
  qualityFile1Name:string;
  certificateName:string;
  legalName:string;
  taxNumber:string;
  street:string;
  city:string;
  selectedCity: any;
  state:string;
  country:string;
  zipcode:string;
  primaryConName:string;
  primaryPhoneNum:string;
  alternativeContact:string;
  customerSupport:string;
  emailAddress:string;
  region:string;
  isAutocompleteOpen: boolean;
  [key: string]: any;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddbrandsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addBrandCall:string|Message=''
  getIndustryApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   errors:{},
   openDialog: true,
   selectedCity:"",
   industryData:[],
   activeComponent: "Company",
   qualityFile2Name:"",
   qualityFile3Name:"",
   qualityFile1Name:"",
   anchorEl: null,
   value: 0,
   totalResults: 140, 
   license:"",
   resultperPage: 12, 
   currentPage: 1 ,
   brandName: "",
   industrySector: "",
   brandTagline: "",
   brandDescription: "",
   selectedFile: null,
   qualityFile1:new File([], ""),
   qualityFile2:new File([], ""),
   qualityFile3:new File([], ""),
   certificateName:"",
   legalName:"",
   taxNumber:"",
   street:"",
   city:"",
   state:"",
   country:"",
   zipcode:"",
   primaryConName:"",
   primaryPhoneNum:"",
   alternativeContact:"",
   customerSupport:"",
   emailAddress:"",
   region:"",
   isAutocompleteOpen: false,
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
    this.getIndustry();
// Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      if (apiRequestCallId === this.addBrandCall) {
        this.handleAddIndustryResponse(responseJson);
      }
       else if (apiRequestCallId === this.getIndustryApiCallId) {
          this.handleGetIndustryResponse(responseJson);
        }
       
      }
  }

  handleAddIndustryResponse(responseJson: any) {
    if (responseJson?.data) {
      const brandCatalogueRoute = '/Brands'; 
      window.location.href = brandCatalogueRoute; 
      this.getIndustry(); 
    }
  }

  validateFields = () => {
    const errors: Record<string, string> = {};
    const { brandName, industrySector, brandTagline, qualityFile1, brandDescription } = this.state;
  
    if (!brandName) errors.brandName = "Brand Name is required.";
    if (!industrySector) errors.industrySector = "Industry / Sector is required.";
    if (!brandTagline) errors.brandTagline = "Brand Tagline is required.";
    if (!qualityFile1) errors.qualityFile1 = "Brand Logo is required.";
    if (!brandDescription) errors.brandDescription = "Brand Description is required.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };

  validateBusinessInformationFields = () => {
    const errors: Record<string, string> = {};
    const {
      legalName,
      taxNumber,
      street,
      city,
      state,
      country,
      zipcode,
    } = this.state;

    if (!legalName) errors.legalName = "Legal Business Name is required.";
    if (!taxNumber) errors.taxNumber = "Tax Identification Number is required.";
    if (!street) errors.street = "Street is required.";
    if (!city) errors.city = "City is required.";
    if (!state) errors.state = "State is required.";
    if (!country) errors.country = "Country is required.";

    this.setState({ errors });

    return Object.keys(errors).length === 0; 
  };

  validateBrandConDetailsFields = (): boolean => {
    const errors: Record<string, string> = {};
    const {
      primaryConName,
      primaryPhoneNum,
      alternativeContact,
      customerSupport,
      emailAddress,
    } = this.state;
  
    if (!primaryConName?.trim()) errors.primaryConName = "Primary Contact Name is required.";
    if (!primaryPhoneNum?.trim()) errors.primaryPhoneNum = "Primary Phone Number is required.";
    if (!alternativeContact?.trim()) errors.alternativeContact = "Alternative Contact Information is required.";
    if (!customerSupport?.trim()) errors.customerSupport = "Customer Support Contact Details are required.";
    if (!emailAddress?.trim()) errors.emailAddress = "Email Address is required.";
    else if (!/\S+@\S+\.\S+/.test(emailAddress)) errors.emailAddress = "Invalid Email Address format.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };

  handleSaveBrandConDetails = () => {
    if (this.validateBrandConDetailsFields()) {
      this.setState({ value: 2 })
      console.log("Validation passed. Saving...");
    } else {
      console.log("Validation failed. Fix errors.");
    }
  };

  validateOtherTabsFields = (): boolean => {
    const errors: Record<string, string> = {};
    const { selectedCity, region } = this.state;
  
    if (!region?.trim()) errors.region = "Region is required.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };
  
  handleSaveOtherTabs = () => {
    if (this.validateOtherTabsFields()) {
      this.setState({ value: 3 })
      console.log("Validation passed. Saving...");
    } else {
      console.log("Validation failed. Fix errors.");
    }
  };
  
  handleSave = () => {
    if (this.validateBusinessInformationFields()) {
      console.log("Validation passed. Saving...");
      this.setState({ value: 4 })  
    } else {
      console.log("Validation failed. Fix errors.");
    }
  };

  validateBrandDocuments = () => {
    const errors: Record<string, string> = {};
    const { certificateName, license, qualityFile2, qualityFile3 } = this.state;
  
    if (!certificateName) errors.certificateName = "Certificate Name is required.";
    if (!qualityFile2 && !qualityFile3) errors.fileUpload = "At least one document upload is required.";
  
    this.setState({ errors });
  
    return Object.keys(errors).length === 0; 
  };
  

  handleSubmit = () => {
  if (this.validateFields()) {
    console.log("Form submitted successfully!");
    this.setState({ value: 1 })
  } else {
    console.log("Validation failed!");
  }
};

  handleGetIndustryResponse(responseJson: any) {
    if (responseJson?.data) {
      const { count, items, page } = responseJson.meta?.pagy || {}; 
    
      this.setState({
        industryData: responseJson.data, 
        totalResults: count || 0,       
        resultperPage: items || 12,    
        currentPage: page || 1,       
      });
    }
  }
  postAddBrandApi = async () => {
    if (!this.validateBrandDocuments()) {
      return; 
    }

      const header = {
        token: await getStorageData("authToken"),
      };
      
      const formData = new FormData();
      formData.append("brand[name]", this.state.brandName || "");
      formData.append("brand[tagline]", this.state.brandTagline || "");
      formData.append("brand[description]", this.state.brandDescription || "");
      formData.append("brand[industry_sector_id]", this.state.industrySector || "");
      formData.append("brand[primary_contact_name]", this.state.primaryConName || "");
      formData.append("brand[primary_phone_number]", this.state.primaryPhoneNum || "");
      formData.append("brand[primary_contact_number]", this.state.primaryPhoneNum || "");
      formData.append("brand[altranative_contact_number]", this.state.alternativeContact || "");
      formData.append("brand[customer_support_contact_details]", this.state.customerSupport || "");
      formData.append("brand[email]", this.state.emailAddress || "");
      formData.append("brand[legal_bussness_name]", this.state.legalName || "");
      formData.append("brand[tax_identification_number]", this.state.taxNumber || "");
      formData.append("brand[street]", this.state.street || "");
      formData.append("brand[city]", this.state.city || "");
      formData.append("brand[state]", this.state.state || "");
      formData.append("brand[country]", this.state.country || "");
      formData.append("brand[zipcode]", this.state.zipcode || "");
      formData.append("brand[region][][city]", this.state.city || "");
      formData.append("brand[region][][region][]", this.state.region || "");
      formData.append("brand[brand_certificates_attributes][][certificate_name]", this.state.certificateName || "");
      formData.append("brand[brand_certificates_attributes][][document_type]", "test");
      formData.append("brand[brand_certificates_attributes][][certificate_file]", this.state.qualityFile2 || null);
      formData.append("brand[brand_certificates_attributes][][certificate_name]", this.state.certificateName || "");
      formData.append("brand[brand_certificates_attributes][][document_type]", "test");
      formData.append("brand[brand_certificates_attributes][][certificate_file]", this.state.qualityFile3 || null);
      formData.append("brand[image]", this.state.qualityFile1 || null);
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addBrandCall = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addBrandApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
  };
  

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: keyof S): void => {
    const { value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      errors: { ...prevState.errors, [fieldName]: undefined }, 
    }));
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (file) {
      this.setState((prevState) => {
        let updatedState = { ...prevState };
        if (fieldName === "qualityFile1") {
          updatedState.qualityFile1 = file; 
          updatedState.qualityFile1Name = file.name; 
        }
       else if (fieldName === "qualityFile2") {
          updatedState.qualityFile2 = file; 
          updatedState.qualityFile2Name = file.name; 
        } else if (fieldName === "qualityFile3") {
          updatedState.qualityFile3 = file; 
          updatedState.qualityFile3Name = file.name; 
        }
        updatedState.errors = { ...prevState.errors, [fieldName]: undefined };
  
        return updatedState;
      });
    }
  };
  
  
  


  getIndustry = async () => {
    const header = {
      "Content-Type": configJSON.ContentType,
      token: await getStorageData("authToken"),
    };
  
    const queryParams = `?page=${this.state.currentPage}&items=${this.state.resultperPage}`;
    const apiEndpoint = `${configJSON.industryGetAPIEndPoint}${queryParams}`;
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getIndustryApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /*istanbul ignore next*/
  handleAutocompleteClose = () => {
    this.setState({ isAutocompleteOpen: false });
  };
  handleTabChange = (newValue: number): void => {
    this.setState({ value: newValue});
  }

  handleAddCityClick = () => {
    this.setState({ isAutocompleteOpen: true });
  };

  // Customizable Area End
}
